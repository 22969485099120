import request from '@/common/axios';

// 用户推荐奖励
export function fetchPusherSetting() {
    return request({
        url: '/web/admin/userInviteRecord/getInviteAmount',
        method: 'post',
        data:{}
    });
}

// 修改推荐奖励
export function updatePusherSetting(data) {
    return request({
        url: '/web/admin/userInviteRecord/updateInviteAmount',
        method: 'POST',
        data: data
    });
}

// 新用户优惠
export function newUserCouponSetting(data) {
    return request({
        url: '/web/admin/coupon/getNewUserCouponList',
        method: 'get',
        data: data
    });
}

// 添加新用户优惠
export function addNewUserCoupon(data) {
    return request({
        url: '/web/admin/coupon/addNewUserCoupon',
        method: 'post',
        data: data
    });
}

// 删除新用户优惠
export function delNewUserCoupon(data) {
    return request({
        url: '/web/admin/coupon/deleteNewUserCoupon',
        method: 'post',
        data: data
    });
}


// 新用户优惠优惠券列表
export function newUserCouponList(data) {
    return request({
        url: '/web/admin/coupon/getSimpleOnlineCouponList',
        method: 'get',
        data: data
    });
}
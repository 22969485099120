<template>
  <el-dialog
    title="增加优惠券"
    :close-on-click-modal="isClickModalClose"
    v-model="isShow"
    :width="defaultWidth"
    @close="closeModal"
  >
    <el-form
      :ref="formName"
      :model="modalData"
      :rules="ruleValidate"
      label-width="110px"
    >
      
      <el-form-item label="优惠券" prop="id">
        <el-select placeholder="请选择优惠券" v-model="modalData.id">
          <el-option
            v-for="(item, index) in list"
            :key="index"
            :label="item.couponName"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button @click="closeModal"> 取消 </el-button>
      <el-button @click="submit" type="primary"> 确定 </el-button>
    </template>
  </el-dialog>
</template>

<script>
import modalMixin from "@/mixins/modalMixin";
import {
    newUserCouponList
} from "@/api/setting";
export default {
  mixins: [modalMixin],
  emits: ["submit"],
  props: ["modalData"],
  data() {
    return {
      formName: "form",
      list: [],
      ruleValidate: {
        id: [
          {
            required: true,
            message: "优惠券不能为空",
            trigger: "change",
          },
        ],
      },
    };
  },

  mounted() {
    this.getCounontList();
  },

  methods: {
    getCounontList(){
      newUserCouponList().then(res=>{
        this.list = res.data.data || [];
      })
    },
    submit() {
      let obj = {
        id: this.modalData.id
      };
      
      console.log(this.modalData,'========modalData');
      this.validateForm().then((res) => {
        this.$emit("submit", obj);
      });
    },
  },
};
</script>
<template>
<div>
   <el-button type="primary" @click="showModal">增加新用户优惠券</el-button>
</div>
  <div>
    <el-table
      border
      :data="dataSource"
      class="page-top-space"
      row-class-name="table-row"
    >
      <el-table-column label="id" prop="id" width="50"></el-table-column>
      <el-table-column label="优惠券名称" prop="couponName">
      </el-table-column>
      <el-table-column label="优惠金额" prop="couponPrice" align="right">
        <template #default="scope">
          <span>
            {{scope.row.couponPrice / 100}}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="有效期" prop="days">
      </el-table-column>


      <el-table-column label="操作">
        <template #default="scope">
          <span
            class="option option-danger"
            v-auth="this.per.SHOW_USER_DETAIL"
            @click="delCoupon(scope.row)"
            >删除</span
          >
        </template>
      </el-table-column>
    </el-table>
   
    <AddCounponModel
      :ref="MODAL_KEY.ADD_COUNPON"
      :modalData="modalData"
      @submit="submitSetting"
    ></AddCounponModel>
  </div>
</template>


<script>
import {
   newUserCouponSetting,
    addNewUserCoupon,
    delNewUserCoupon
} from "@/api/setting";
import AddCounponModel from "./components/AddCounponModel";

export default {
  components: { AddCounponModel },
  data() {
    return {
      MODAL_KEY: {
        ADD_COUNPON: "ADD_COUNPON"
      },
      dataSource:[],
      modalData:{},
      total: 0,
    };
  },

 mounted(){
  this.getData();
 },

  methods: {
    getData() {
      newUserCouponSetting().then((res) => {
        this.dataSource = res.data.data || [];
      });
    },

    showModal(){
      this.modalData={};
      this.$refs[this.MODAL_KEY.ADD_COUNPON].showModal();
    },

    delCoupon(data) {
      delNewUserCoupon({id:data.id}).then(res=>{
        this.$message.success("删除成功");
        this.getData();
      })
    },

    submitSetting(data) {
      addNewUserCoupon(data).then((res) => {
        this.$message.success("操作成功");
        this.getData();
        this.$refs[this.MODAL_KEY.ADD_COUNPON].closeModal();
      });
    },


  },
};
</script>
